import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Header from "Components/Header/Header";
import React, { useState, useEffect, useRef } from "react";
import "./EvenementDetail.scss";
import event1 from "assets/Event1.webp";
import Footer from "Components/Footer/Footer";
import { useParams } from "react-router-dom";
import Loader from "Components/Loader/loader";
import {
  formatDateHourFrenchLocale,
  formatDate,
  formatHourFrenchLocale,
} from "Services/functions";
import { fetchGet, useFetchGet, useFetchGetPagination } from "Services/api";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateEvents } from "Redux/Actions/eventsActions";
import EventActuConseilRandom from "Components/EventActuConseilRandom/EventActuConseilRandom";
import axios from "axios";
import ModalQuestion from "./ModalQuestion";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { sanitize } from "dompurify";
import { QuillNoToolbarOptions } from "../../QuillOptions/QuillOptions";

const EvenementDetail = (props) => {
  const successUpdate = useRef(null);
  const refQuestions = useRef(null);
  // récupération des événements
  let { id } = useParams();
  const { t } = useTranslation("common");

  const event = useFetchGet(`/events/${id}`, props.auth.token);

  const [participantsUrl, setParticipantsUrl] = useState(null);
  const eventParticipants = useFetchGetPagination(
    participantsUrl,
    props.auth.token
  );

  const [loaderRegister, setLoaderRegister] = useState(false);
  const eventDetailRef = useRef(null);

  let eventsRegistered = {
    data: null,
    loaded: false,
  };
  eventsRegistered = useFetchGet(
    `/user_events/${props.auth.userConnected?.id}`,
    props.auth.token
  );
  const [registeredParticipants, setRegisteredParticipants] = useState(0);
  const [isMyEvent, setIsMyEvent] = useState(false);
  const [eventDetail, setEventDetail] = useState();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  useEffect(() => {
    if (eventsRegistered.loaded) {
      props.handleUpdateEvents({ myEvents: eventsRegistered.data });
    }
    if (eventParticipants.loaded && event.loaded) {
      props.handleUpdateEvents({
        eventParticipants: eventParticipants?.data?.filter(
          (evenement) => evenement.event.split("/")[3] == event.data.id
        ),
      }) || [];
    }
    if (event.loaded) {
      if (event.data) setEventDetail(event.data);
      else setEventDetail(event.error);
    }
  }, [eventsRegistered.loaded, eventParticipants.loaded, event.loaded]);

  useEffect(() => {
    if (eventDetailRef.current !== null) {
      window.scroll({
        top: eventDetailRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [loaderRegister]);

  const createEventParticipantsURL = () => {
    if (eventDetail && eventDetail.id) {
      let url = process.env.REACT_APP_BASE_URL_API;
      url += "/event_participants";
      url = new URL(url);
      url.searchParams.append("event", eventDetail.id);
      setParticipantsUrl(url);
    }
  };

  useEffect(() => {
    createEventParticipantsURL();
  }, [eventDetail]);

  const updateEventDetail = async () => {
    let response = await fetchGet(`/events/${id}`, props.auth.token);
    setEventDetail(response);
  };

  const registerParticipant = (idEvent) => {
    setLoaderRegister((prec) => !prec);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_API}/event_participants/registration`,
        {
          event: `/api/events/${idEvent}`,
        },
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                "Content-Type": "application/json",

                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
                "Content-Type": "application/json",
              },
        }
      )
      .then((res) => {
        props.handleUpdateEvents({
          eventParticipants: props.events.eventParticipants.concat(res.data),
          myEvents: props.events.myEvents.concat(eventDetail),
        });
        setLoaderRegister((prec) => !prec);
        updateEventDetail();
      })
      .catch((error) => console.log(error));
  };

  const unregisterParticipant = (idEvent) => {
    setLoaderRegister((prec) => !prec);
    const idEventParticipant = props.events.eventParticipants.filter(
      (participant) => participant.user.id === props.auth.userConnected.id
    )[0];
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL_API}/event_participants/${idEventParticipant.id}`,
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                "Content-Type": "application/json",

                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
                "Content-Type": "application/json",
              },
        }
      )
      .then(() => {
        props.handleUpdateEvents({
          eventParticipants: props.events.eventParticipants.filter(
            (participant) => participant.id !== idEventParticipant.id
          ),
          myEvents: props.events.myEvents.filter(
            (event) => event.id !== idEvent
          ),
        });
        setLoaderRegister((prec) => !prec);
        updateEventDetail();
      })
      .catch((error) => console.log(error));
  };

  const downloadParticipantList = () => {
    let url = `${process.env.REACT_APP_BASE_URL_API}/events/download_participant_list/${id}/csv`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.auth.token}`,
          accept: "application/json",
        },
        responseType: "blob",
      })
      .then((res) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(res.data);
        link.setAttribute("download", `Participant_List`);
        link.click();
      })
      // Si il y a une erreur, on l'affiche dans la console
      .catch((error) => {
        console.log(error.message);
      });
  };

  const showSuccess = () => {
    successUpdate.current.show({
      severity: "success",
      summary: "Question envoyée",
      detail: "Votre question a bien était posée",
      life: 3000,
    });
  };

  // Corsp du mail
  let stringFromQuestions = "Réponses aux questions : \n \n";
  eventDetail?.questions?.forEach(
    (question, index) =>
      (stringFromQuestions += `Question ${index + 1} : ${question} \n \n`)
  );

  useEffect(() => {
    setRegisteredParticipants(
      props.events?.eventParticipants?.filter((x) => !x.registrationInPending)
        ?.length || 0
    );
  }, [props.events.eventParticipants]);

  useEffect(() => {
    if (!props.events.myEvents) return;
    setIsMyEvent(props.events.myEvents.some((event) => event.id == id));
  }, [props.events.myEvents]);

  const participantsQuotas = () => {
    let numerateur = 0;
    if (registeredParticipants <= 1) {
      numerateur = registeredParticipants + " participant";
    } else if (registeredParticipants <= eventDetail.maxNumberOfParticipants) {
      numerateur = registeredParticipants + " participants";
    } else {
      numerateur = eventDetail.maxNumberOfParticipants + " participants";
    }

    return numerateur + ` / ${eventDetail.maxNumberOfParticipants}`;
  };

  return (
    <div className="evenement_detail_container" ref={eventDetailRef}>
      <Header />
      <Toast ref={successUpdate} />
      <h1 className="evenement_detail_container__title h1_trait_dessus">
        {t("evenements.detailEvent")}
      </h1>
      {event.loaded && eventDetail ? (
        <>
          {typeof eventDetail === "string" ? (
            <div className="evenement_detail_container__error">
              {
                "Vous n'avez pas accès à cet événement, veuillez vous connecter ou vous inscrire"
              }
            </div>
          ) : (
            <div className="evenement_detail_container__top">
              <div className="evenement_detail_container__top__body">
                {eventDetail?.imageUrl || eventDetail?.nexusImageUrl ? (
                  <img
                    className="evenement_detail_container__top__body__img"
                    src={
                      eventDetail.nexusImageUrl
                        ? eventDetail.nexusImageUrl
                        : eventDetail.imageUrl
                    }
                    alt="Image de l'event"
                  ></img>
                ) : (
                  <img
                    className="evenement_detail_container__top__body__img"
                    src={event1}
                    alt="Image de l'event"
                  ></img>
                )}

                <div className="evenement_detail_container__top__body__texte">
                  <h1>{eventDetail?.title}</h1>
                  <h5 className="evenement_detail_container__top__body__texte__date">
                    {eventDetail.endingAt ? (
                      <>
                        {(props.i18n.language === "en" ? "From " : "Du ") +
                          formatDate(eventDetail.startingAt)}
                        {eventDetail.startingHour &&
                          (props.i18n.language === "en" ? " at " : " à ") +
                            formatHourFrenchLocale(
                              eventDetail.startingHour
                            )}{" "}
                        {(props.i18n.language === "en" ? "to " : "au ") +
                          formatDate(eventDetail.endingAt)}
                        {eventDetail.endingHour &&
                          (props.i18n.language === "en" ? " at " : " à ") +
                            formatHourFrenchLocale(eventDetail.endingHour)}
                      </>
                    ) : (
                      <>
                        {(props.i18n.language === "en" ? "On the " : "Le ") +
                          formatDateHourFrenchLocale(eventDetail.startingAt)}
                      </>
                    )}
                  </h5>
                  <h5 className="evenement_detail_container__top__body__texte__categorie">
                    {t("createGroup.category")} : {eventDetail?.category?.label}
                  </h5>
                  <h5 className="evenement_detail_container__top__body__texte__categorie">
                    {t("offresApresConnexionPage.searchBarBottomLieu")} :{" "}
                    {eventDetail?.location}
                  </h5>
                  {eventDetail?.link && (
                    <h5 className="evenement_detail_container__top__body__texte__categorie">
                      {t("createGroup.link")}{" "}
                      <a
                        className="evenement-link"
                        href={
                          eventDetail?.link?.includes("http")
                            ? eventDetail?.link
                            : `https://${eventDetail?.link}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {eventDetail?.link}
                      </a>
                    </h5>
                  )}
                  {props.auth.isConnected && eventDetail.register && (
                    <h5
                      onClick={() => {
                        (props.auth.userConnected.userRoles.some(
                          (role) =>
                            role.roleName == "ROLE_ADMIN_DU_PORTAIL" ||
                            role.roleName == "ROLE_PERSONNEL_UNIVERSITE"
                        ) ||
                          eventDetail?.createdBy.id ===
                            props.auth.userConnected.id) &&
                          downloadParticipantList();
                      }}
                      className="evenement_detail_container__top__body__texte__categorie number_participant"
                    >
                      <i className="pi pi-users"></i>{" "}
                      {eventDetail.maxNumberOfParticipants &&
                        registeredParticipants &&
                        participantsQuotas()}
                    </h5>
                  )}
                  <div
                    className="evenement_detail_container__top__body__texte__inscriptionStatus"
                    style={{
                      color: eventDetail?.fulfilled ? "#e24c4c" : "#5eb6a1",
                    }}
                  >
                    {
                      //? On vérifie si l'événement est complet
                      eventDetail?.fulfilled
                        ? //? Si l'événement est complet, on affiche que les inscriptions sont closes
                          t("evenements.closedInscriptions")
                        : //? Sinon, on affiche un message qu'elles sont ouvertes (avec la date de fin des inscriptions si elle existe)
                          `${t("evenements.openInscriptions")} ${
                            eventDetail?.registerEnd != undefined
                              ? t("evenements.openInscriptionsUntil", {
                                  date: formatDate(eventDetail.registerEnd),
                                })
                              : ""
                          }`
                    }
                  </div>

                  <p className="evenement_detail_container__top__body__texte__description">
                    <ReactQuill
                      value={sanitize(eventDetail?.description)}
                      readOnly
                      theme={"snow"}
                      modules={QuillNoToolbarOptions.modules}
                      formats={QuillNoToolbarOptions.formats}
                    />
                  </p>

                  {eventDetail?.questions?.length > 0 && (
                    <h5
                      className="evenement_detail_container__top__body__texte__anchor"
                      onClick={() => {
                        if (refQuestions.current !== null) {
                          window.scroll({
                            top: refQuestions.current.offsetTop,
                            behavior: "smooth",
                          });
                        }
                      }}
                    >
                      {" "}
                      {t("evenements.answerQuestions")}
                    </h5>
                  )}

                  {eventDetail.register &&
                    new Date(eventDetail.registerEnd) > new Date() && (
                      <div className="evenement_detail_container__top__body__btns">
                        {props.auth.isConnected &&
                          (loaderRegister ? (
                            <Loader />
                          ) : //? Si l'événement est complet, on affiche le bouton de désinscription (si l'utilisateur est inscrit)
                          eventDetail?.fulfilled ? (
                            isMyEvent && (
                              <BtnBleu
                                btnAction={() =>
                                  unregisterParticipant(eventDetail.id)
                                }
                                btnTexte={t("evenements.unregister")}
                              />
                            )
                          ) : (
                            //? Sinon, on afficher soit le bouton d'inscription, soit le bouton de désinscription
                            <BtnBleu
                              btnAction={() =>
                                isMyEvent
                                  ? unregisterParticipant(eventDetail.id)
                                  : registerParticipant(eventDetail.id)
                              }
                              btnTexte={
                                isMyEvent
                                  ? t("evenements.unregister")
                                  : t("evenements.register")
                              }
                            />
                          ))}
                      </div>
                    )}
                  {modalIsVisible && (
                    <ModalQuestion
                      modalIsVisible={modalIsVisible}
                      setModalIsVisible={setModalIsVisible}
                      idEvent={
                        props.events.eventParticipants.filter(
                          (participant) =>
                            participant.user.id === props.auth.userConnected.id
                        )[0]
                      }
                      showSuccess={() => showSuccess()}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}

      {eventDetail?.questions?.length > 0 && (
        <div
          className="evenement_detail_questions_container"
          ref={refQuestions}
        >
          <div className="evenement_detail_questions_container__body">
            <h1>{t("evenements.questions")}</h1>
            {eventDetail.questions.map((question, index) => (
              <span key={index}>
                Question {index + 1} : {question}
              </span>
            ))}
            <h5>{t("evenements.answerMail")}</h5>
            <a
              href={`mailto:${
                eventDetail.emailCreator
              }?body=${encodeURIComponent(stringFromQuestions)}`}
              title={t("evenements.answerSend", {
                mail: eventDetail.emailCreator,
              })}
            >
              {eventDetail.emailCreator}
            </a>
          </div>
        </div>
      )}
      <div className="evenement_detail_container__bottom">
        <div className="evenement_detail_container__bottom__title">
          <h1>{t("evenements.evenementsSimilaires")}</h1>
        </div>
        <EventActuConseilRandom event={eventDetail} />
        <div className="evenement_detail_container__bottom__event"></div>
      </div>
      <Footer />
    </div>
  );
};
EvenementDetail.propTypes = {
  handleUpdateEvents: PropTypes.func,
  events: PropTypes.object,
  auth: PropTypes.object,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
  events: state.events,
  auth: state.auth,
  i18n: state.i18n,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EvenementDetail);
