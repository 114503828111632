import React, { useRef, useState } from "react";
import "./ActualiteCard.scss";
import PropTypes from "prop-types";
import { Menu } from "primereact/menu";
import BtnRond from "Components/Boutons/BtnRond/btn-rond";
import { FaEllipsisH } from "react-icons/fa";
import img_groupe1 from "assets/Event1.webp";
import { connect } from "react-redux";
import Modal from "Components/Modal/Modal";
import { InputTextarea } from "primereact/inputtextarea";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { updateNews } from "Redux/Actions/newsActions";
import axios from "axios";
import ActualitesCreation from "Components/Actualites/ActualitesCreation/ActualitesCreation";
import { truncate } from "Services/functions";
import { Link } from "react-router-dom";
import { fetchGet } from "Services/api";
const ActualiteManagerCard = (props) => {
  const [openRefusInterface, setOpenRefusInterface] = useState(false);
  const [openModifyInterface, setOpenModifyInterface] = useState(false);
  const [errorRefus, setErrorRefus] = useState(false);
  const [openMotifRefusInterface, setOpenMotifRefusInterface] = useState(false);

  const [newsDetail, setNewsDetail] = useState(null);

  const [refus, setRefus] = useState("");
  const menu = useRef(null);
  const itemsPublie = [
    {
      label: "Modifier",
      command: async () => {
        const news = await fetchGet("/news/" + props.id, props.auth.token);
        setNewsDetail(news);
        setOpenModifyInterface(!openModifyInterface);
      },
    },
    { separator: true },
    {
      label: "Supprimer",
      command: () => {
        deleteNews();
      },
    },
  ];
  const itemsBrouillon = [
    {
      label: "Modifier",
      command: () => {
        setOpenModifyInterface(!openModifyInterface);
      },
    },
    { separator: true },
    {
      label: "Supprimer",
      command: () => {
        deleteNews();
      },
    },
  ];
  const itemsAttente = [
    {
      label: "Modifier",
      command: () => {
        setOpenModifyInterface(!openModifyInterface);
      },
    },
    { separator: true },
    {
      label: "Annuler",
      command: () => {
        deleteNews();
      },
    },
  ];
  const itemsRefuse = [
    {
      label: "Renouveler la demande",
      command: () => {
        setOpenMotifRefusInterface(!openMotifRefusInterface);
      },
    },
    // { separator: true },
    // {
    //   label: "Supprimer",
    //   command: () => {
    //     deleteNews();
    //   },
    // },
  ];

  // uniquement pour le rôle admin
  const itemsRefuseAdmin = [
    {
      label: "Supprimer",
      command: () => {
        deleteNews();
      },
    },
  ];
  const itemsAttenteAdmin = [
    {
      label: "Accepter",
      command: () => {
        modifyCard("publish");
      },
    },
    { separator: true },
    {
      label: "Refuser",
      command: () => {
        setOpenRefusInterface(!openRefusInterface);
      },
    },
  ];

  const Statut = () => {
    switch (props.statut.statusLabel) {
      case "Publié":
        return itemsPublie;
      case "Brouillon":
        return itemsBrouillon;
      case "En Attente":
        switch (
          props.auth.userConnected.userRoles.some(
            (role) => role.roleName === "ROLE_ADMIN_DU_PORTAIL"
          )
        ) {
          case true:
            return itemsAttenteAdmin;
          default:
            return itemsAttente;
        }
      case "Refusé":
        switch (
          props.auth.userConnected.userRoles.some(
            (i) => i.roleName === "ROLE_ADMIN_DU_PORTAIL"
          )
        ) {
          case true:
            return itemsRefuseAdmin;
          default:
            return itemsRefuse;
        }

      default:
        break;
    }
  };

  const modifyCard = (path) => {
    if (!props.transmitterName) return;

    let url = `${process.env.REACT_APP_BASE_URL_API}/news/${props.id}/${path}`;
    if (props.transmitterName === "Recruteur") {
      url += "/recruiter";
      if (path === "publish") url = url.replace("publish", "validate");
    }

    let data = {};
    if (path === "refuse") data = { feedback: refus };
    axios
      .post(url, data, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then((res) => {
        let tempVar = props.news.dataFromPagination;
        if (props.transmitterName === "Recruteur" && path === "validate") {
          tempVar.data = tempVar.data.filter((news) => news.id !== res.data.id);
          props.auth.toast?.current?.show({
            severity: "success",
            summary: "Succès",
            detail:
              "L'actualité a bien été acceptée, et sera désormais visible dans l'onglet 'Actus Universités' après un léger délai de traitement",
            life: 6000,
          });
        } else {
          tempVar.data = tempVar.data.map((news) => {
            if (news.id === res.data.id) {
              return res.data;
            } else {
              return news;
            }
          });
        }
        props.handleUpdateNews({
          dataFromPagination: tempVar,
        });
      });
  };

  const deleteNews = () => {
    if (!props.transmitterName) return;

    let url = `${process.env.REACT_APP_BASE_URL_API}/news/${props.id}`;
    if (props.transmitterName === "Recruteur") {
      url += "/recruiter";
    }

    axios
      .delete(url, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then(() => {
        let tempVar = props.news.dataFromPagination;
        tempVar.data = tempVar.data.filter((event) => event.id !== props.id);
        props.handleUpdateNews({
          dataFromPagination: tempVar,
        });
      });
  };
  return (
    <div className="gestion_evenements_container">
      <div className="gestion_evenements_container__left">
        <div>
          <Link
            to={{
              pathname: `/actu-detail/${props.id}`,
              search: `?r=${props.transmitterName === "Recruteur"}`,
            }}
            className="logo__card__gestion"
          >
            {props.image === "img1" ? (
              <>
                <img src={img_groupe1} alt="Fond news" />
                <div
                  className="image__bg"
                  style={{ backgroundImage: `url(${img_groupe1})` }}
                ></div>
              </>
            ) : (
              <>
                <img
                  src={props.image}
                  alt="Fond news"
                  onError={(e) => {
                    e.target.src = img_groupe1;
                  }}
                />
                <div
                  className="image__bg"
                  style={{
                    backgroundImage: `url(${props.image})`,
                  }}
                ></div>
              </>
            )}
          </Link>
        </div>

        <div className="groupe_menu mobile">
          <Menu
            appendTo={document.getElementById("app")}
            model={Statut()}
            popup
            ref={menu}
            style={{ textAlign: "center" }}
          />
          <BtnRond btnAction={(event) => menu.current.toggle(event)}>
            <FaEllipsisH size={"1.5rem"} />
          </BtnRond>
        </div>
        <div className="gestion_evenements_container__left__info">
          <Link
            to={{
              pathname: `/actu-detail/${props.id}`,
              search: `?r=${props.transmitterName === "Recruteur"}`,
            }}
          >
            <h5 className="title">{truncate(props.titre, 122)}</h5>
          </Link>
          <h2></h2>

          <div className="gestion_evenements_container__left__info__bottom">
            <h2>
              <i className="pi pi-calendar"></i>
              {props.date}
            </h2>
          </div>
        </div>
      </div>

      <div className="gestion_evenements_container__bouton">
        <div className="groupe_menu desktop">
          <Menu
            appendTo={document.getElementById("app")}
            model={Statut()}
            popup
            ref={menu}
            style={{ textAlign: "center" }}
          />
          <BtnRond btnAction={(event) => menu.current.toggle(event)}>
            <FaEllipsisH size={"1.5rem"} />
          </BtnRond>
        </div>
        {props.statut.statusLabel == "Publié" && (
          <span className="publie">Publiée</span>
        )}
        {props.statut.statusLabel == "Brouillon" && (
          <span className="brouillon">Brouillon</span>
        )}
        {props.statut.statusLabel == "En Attente" && (
          <span className="attente">En attente</span>
        )}
        {props.statut.statusLabel == "Refusé" && (
          <span className="refuse">Refusée</span>
        )}
      </div>
      {openRefusInterface && (
        <Modal
          visible={openRefusInterface}
          setVisible={setOpenRefusInterface}
          header={`Justifiez votre refus de l'actualité`}
        >
          <div className="modal_motif_refus">
            <InputTextarea
              className="modal_refus"
              rows={6}
              placeholder="Justifiez votre refus"
              value={refus}
              onChange={(e) => setRefus(e.target.value)}
            />
            {errorRefus && (
              <span className="error_refus_length">
                Le motif de refus doit faire au moins 10 caractères
              </span>
            )}
            <BtnBleu
              btnTexte="Envoyer"
              btnAction={() => {
                if (refus.length > 9) {
                  setErrorRefus(false);
                  setRefus("");
                  setOpenRefusInterface(false);
                  modifyCard("refuse");
                } else {
                  setErrorRefus(true);
                }
              }}
            />
          </div>
        </Modal>
      )}
      {openMotifRefusInterface && (
        <Modal
          visible={openMotifRefusInterface}
          setVisible={setOpenMotifRefusInterface}
          header={`Motif du refus`}
        >
          <div className="motif_refus_interface">
            <div className="motif_refus_interface_message">
              <span className="motif_refus_interface_message_title">
                Motif du refus :
              </span>
              <span className="motif_refus_interface_message_text">
                {props.feedback
                  ? props.feedback
                  : " Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim maiores vero totam exercitationem at asperiores quibusdam eligendi labore fuga, porro tempora dolor nihil repellat error aut distinctio et earum molestiae accusamus rem."}
              </span>
            </div>
            <div className="motif_refus_interface_bouton">
              <BtnBleu
                btnTexte="Renouveler la demande"
                btnAction={() => {
                  setOpenModifyInterface(true);
                  setOpenMotifRefusInterface(false);
                }}
              ></BtnBleu>
            </div>
          </div>
        </Modal>
      )}
      {openModifyInterface && (
        <Modal
          visible={openModifyInterface}
          setVisible={setOpenModifyInterface}
          header={`Modifiez votre actu`}
        >
          <ActualitesCreation
            activeIndex={props.items.itemsActuAdmin.findIndex(
              (v) => v.label == "Créer une actu/conseil"
            )}
            items={props.items.itemsActuAdmin}
            value={newsDetail}
            setVisible={setOpenModifyInterface}
          ></ActualitesCreation>
        </Modal>
      )}
    </div>
  );
};

ActualiteManagerCard.propTypes = {
  titre: PropTypes.string,
  date: PropTypes.string,
  statut: PropTypes.string,
  image: PropTypes.string,
  auth: PropTypes.object,
  news: PropTypes.object,
  handleUpdateNews: PropTypes.func,
  id: PropTypes.number,
  items: PropTypes.object,
  feedback: PropTypes.string,
  transmitterName: PropTypes.string,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  news: state.news,
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateNews: (value) => {
    dispatch(updateNews(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActualiteManagerCard);
